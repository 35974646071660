import {Box, Heading, Paragraph, ResponsiveContext, Text} from "grommet";
import {Checkmark} from 'grommet-icons';
import {useContext} from 'react';
import ScrollToTop from "./ScrollToTop";

const About = () => {
    const size = useContext(ResponsiveContext);

    let width = '60%'
    if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
        width = '100%'
    }

    return (
        <Box align="center" margin={{
            bottom: "large"
        }}>
            <ScrollToTop/>
            <Box background={
                {
                    image: "url(./assets/images/home_page.jpg)",
                    size: "cover",
                    color: "neutral-1",
                    opacity: "strong"
                }
            }
                 style={{
                     background: "rgba(0, 0, 0, 0.5)"
                 }}
                 height="medium"
                 width="100%"
                 margin={{
                     bottom: "large"
                 }}
                //   align="center"
                 justify="center"
            >
                <Heading
                    alignSelf="center"
                    className="title"
                    style={{
                        borderBottom: "2px solid purple"
                    }}>About Us</Heading>
            </Box>
            <Box
                width={width}
                align="center"
            >
                <Heading
                    alignSelf="start"
                    margin={
                        {
                            bottom: "10px"
                        }
                    }
                    className="titleheavy"
                    style={{
                        borderBottom: "1px solid grey"
                    }}
                >Pendo Villas Diani</Heading>

                <Heading margin="none" alignSelf="start" level="2" className="title">A Quiet Holiday Getaway</Heading>
                <Paragraph fill>
                    Our villas are designed to provide extreme luxury and comfort to our guests.
                    We strive to make your stay with us very memorable through our experienced staff.
                    The rooftops overlooking the pool and garden offers you and your family a relaxation point to enjoy
                    the evening breeze.
                    Pendo villas is close to various commercial banks and leading supermarkets to ensure you have all
                    you want during your stay.
                    We look forward to welcoming you to Pendo Villas Diani, <span><Text weight="bold">“The Heart of Getaway Living”.</Text></span>
                    <br/>
                    Our villas are indeed a home a way from home. The Kitchenet is well equipped to ensure you enjoy
                    your favorite meals.

                    The bedrooms are fitted with Air conditioners and fans for your maximum comfort and incase of your
                    little baby, we provide a baby cot at no extra cost.

                </Paragraph>
                <Heading alignSelf="start" className='title'>What we can do</Heading>
                <Box gap='small'>
                    {
                        aboutExperiences.map((item, index) => {
                            return (
                                <Box key={index} gap='small'>
                                    <Box direction="row" align='center' gap='small'>
                                        <Checkmark size="small" color='black'/>
                                        <Heading margin={"none"} level="3">{item.title}</Heading>
                                    </Box>
                                    <Text>{item.description}</Text>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>

        </Box>
    )
}

const aboutExperiences = [
    {
        title: "Standby Transport",
        description: `
        Our in-house van is ready for your use with prior arragement. Just make a request at the reception and our staff will give you the details.
        In-House Chef.
        `
    },
    {
        title: "In-House Chef",
        description: `
        If you do not feel like piecing together your meals, our resident chef will have you covered.
        `
    },
    {
        title: "Your Travel Concierge",
        description: `
        We can organise local tours for you to maximise your experience of Diani. Make sure to include the tours in your room booking. 
        `
    }
]

export default About;