import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter
} from "react-router-dom";
import About from "./About";
import App from "./App";
import Rooms from "./Rooms";
import Gallery from "./Gallery";
import Contact from "./Contact";
import FAQs from "./Faqs";
import Home from "./Home";
import Experience from "./Experience";
import NotFound from "./404Error";
import Book from "./Book";
const color ='#011640';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App color={color}/>,
    children: [
      {
        index: true,
        element: <Home color={color}/>,
      },

      // {
      //   path: "home",
      //   element: <Home color={color}/>,
      // },
      {
        path: "about",
        element: <About color={color}/>,
      },
      {
        path: "one-bedroom",
        element: <Rooms room={
          {
            src: "./assets/images/one_bedroom_2.jpg",
            alt: "Room 1",
            title: "Ret Bedroom",
            description: `
              The one bedroom suite is fitted with a fully-equipped kitchen,
              a guest cloakroom, a study working table, flat screen television with satellite & cable channels,
              complimentary Wi-Fi connectivity and daily housekeeping services.
              `
          }
        } 
        color={color}
        />
      },
      {
        path: "two-bedroom",
        element: <Rooms room={
          {
            src: './assets/images/two_bedroom_1.jpg',
            alt: 'Two Bedroom',
            title: 'Two Bedroom',
            description: `
            The one bedroom suite is fitted with a fully-equipped kitchen,
            master bedroom ensuite with a king sized bed and a rain shower,
            a guest cloakroom, a study working table, flat screen television with satellite & cable channels,
            complimentary Wi-Fi connectivity and daily housekeeping services.
            `
          }
        } 
        color={color}
        />
      },
      {
        path: "gallery",
        element: <Gallery color={color}/>
      },
      {
        path: "book",
        element: <Book color={color}/>
      },
      {
        path: "contact",
        element: <Contact image={
          {
            src: "//v2.grommet.io/assets/IMG_4245.jpg",
            alt: "Room 1",
            title: "ONE BEDROOM"
          }
        }
        color={color}
         />
      },
      {
        path: "faqs",
        element: <FAQs color={color}/>
      },
      {
        path: "experience",
        element: <Experience color={color}/>
      },
      
    ],
    errorElement:<NotFound/>
  },
]);

export default router;