import {Box, Heading, Paragraph, ResponsiveContext, Text} from "grommet";
import {Checkmark} from 'grommet-icons';
import {useContext} from 'react';
import ScrollToTop from "./ScrollToTop";

const Book = () => {
    const size = useContext(ResponsiveContext);
    let width = '60%'

    return (
        <div>
        <booking-widget id="977"></booking-widget>
        </div>
    )
}

export default Book;