import {
  Anchor,
  Box,
  Button,
  Header,
  Image,
  Menu, Nav,
  ResponsiveContext,
  Text
} from "grommet";
import {
  Down,
  Menu as MenuIcon,

  Up
} from "grommet-icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";


const menuIconSize = "16"
const color = "#011640";
const Head = () => {
  const size = useContext(ResponsiveContext);
  let columns = null;
  let justify = 'start'
  if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
    columns = ['1/2', '1/2'];
    justify = 'between'
  } else {
    columns = ['1/4', '3/4']
  }


  return (
    <Header
      justify={justify}
      align="start"
      background="light-5"
      pad="small"
      height="xsmall"
    >

      <Logo />

      <ResponsiveContext.Consumer>
        {(size) =>
          size === 'small' ? (
            <Box justify="end">
              <Menu
                a11yTitle="Navigation Menu"
                dropProps={{ align: { top: 'bottom', right: 'right' } }}
                icon={<MenuIcon color="brand" />}
                justify={"between"}
                items={menuLinks}
              />
            </Box>
          ) : (
            <Nav
              direction="row"
              pad="small"
              justify="between"
              align="center"
              flex={true}

            // width="100%"
            >
              {
                links.map((item, index) => {
                  return (
                    <LinkItem
                      item={item}
                      key={index}
                    />
                  )
                })
              }
          <Button label={<Text color={"white"}>Book Now</Text>} as={Link} to="/book" color={"#011640"} primary/>

            </Nav>
          )
        }
      </ResponsiveContext.Consumer>
    </Header>

  );
};
const menuLinks = [
  {
    label: <Link to={"/"}>Home</Link>
  },
  {
    label: <Link to={"/book"}>Book</Link>
  },
  {
    label: <Link to={"about"}>About</Link>
  },
  {
    label: <Link to={"one-bedroom"}>One Bedroom</Link>
  },
  {
    label: <Link to={"two-bedroom"}>Two Bedroom</Link>
  },
  {
    label: <Link to={"gallery"}>Gallery</Link>
  },
  {
    label: <Link to={"contact"}>Contact Us</Link>
  },
  {
    label: <Link to={"faqs"}>FAQs</Link>

  },
  {
    label: <Link to={"experience"}>Experiences</Link>
  }
];
const links = [
  {
    link: "/",
    title: "Home",
  },
  {
    link: "/about",
    title: "About",
  },
  {
    link: "/rooms",
    title: "Rooms",
    menuItems: [
      {
        label: <Link to="one-bedroom">One Bedroom</Link>
      },
      {
        label: <Link to="Two-bedroom">Two Bedroom</Link>
      },
    ]
  },
  {
    link: "/gallery",
    title: "Gallery",
  },
  {
    link: "/contact",
    title: "Contact Us",
  },
  {
    link: "/faqs",
    title: "FAQs",
  },
  {
    link: "/experience",
    title: "Experiences",
  }
];
const Logo = () => {
  return (
    <Anchor href="https://pendovillasdiani.com/"
      margin={{
        right: "medium"
      }}
    >
      <Image fit="cover" fill src="./assets/images/pendo-logo-white-1.png" />
    </Anchor>
  )
};

const LinkItem = ({ item }) => {
  const [onHover, setonHover] = useState(false);
  const [onOpenMenu, setOpenMenu] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenMenu(false)

      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <Box>
      {
        item.menuItems ? <Menu
          onClick={() => setOpenMenu(!onOpenMenu)}
          ref={ref}
          //onBlur={() => setOpenMenu(!onOpenMenu)}
          label={<Box
            direction="row"
            align="center"
            gap="small"
          >
            <Text color={color} weight="bold">{item.title}</Text>
            {onOpenMenu ?
              <Up color={color} size={menuIconSize} /> :
              <Down color={color} size={menuIconSize} />}
          </Box>}
          open={onHover}
          dropProps={{ align: { top: 'bottom', right: 'right' } }}
          items={item.menuItems}
          icon={false}
          pad="large"
        /> : <Link

          to={item.link}
        >
          <Text weight="bold" color={color}>{item.title}</Text>
        </Link>
      }
    </Box>
  )
};

function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        alert("You clicked outside of me!");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}






export default Head;

//module.exports = enhanceWithClickOutside(LinkItem);